<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>数据统计</template>
      <template v-slot:secondMenu>花费统计</template>
    </breadcrumb-nav>
    <el-card class="filter-container" shadow="never">
      <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
        <el-form-item label="日期范围" prop="date">
          <el-date-picker
            v-model="listQuery.date"
            type="daterange"
            align="right"
            prop="date"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="resetForm('listQuery')">重置</el-button>
        </el-form-item>
      </el-form>
      <div id="charts-main" style="width: 950px;height: 400px;"></div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { getCostDataList } from '../../network/statistics'
import * as echarts from 'echarts'

export default {
  name: 'Cost',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      // 查询参数
      listQuery: {
        date: '',
        page: 1,
        size: 100
      },
      // 花费统计数据
      costDataList: [],
      // 总数据条数
      total: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近三天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      chatMain: '',
      options: {
        title: {
          text: '每日花费'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            }
          }
        ],
        legend: {
          data: ['花费 (元)']
        },
        series: [{
          name: '花费 (元)',
          type: 'line',
          smooth: true
        }]
      }
    }
  },
  mounted () {
    this.chartsMain = echarts.init(document.getElementById('charts-main'))
    this.getDataList(this.listQuery)
  },
  methods: {
    onSubmit () {
      this.getDataList(this.listQuery)
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    getDataList (params) {
      const query = {
        page: params.page,
        size: params.size
      }
      if (params.date !== null && params.date !== undefined && params.date.length > 1) {
        query.from = params.date[0]
        query.to = params.date[1]
      }
      console.log('list query:', query)
      // this.setChartData()
      getCostDataList(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取花费数据失败', 'error')
        }
        this.costDataList = result.data.data
        this.total = result.data.total
        console.log('cost data:', this.costDataList)
        this.setChartData()
      })
    },
    setChartData () {
      this.options.xAxis[0].data = []
      this.options.series[0].data = []
      for (let i = 0; i < this.costDataList.length; i++) {
        this.options.xAxis[0].data.unshift(this.formatDate(this.costDataList[i].date))
        this.options.series[0].data.unshift(Math.round(this.costDataList[i].sum * 100) / 100)
      }
      // this.options.
      this.chartsMain.setOption(this.options)
    },
    formatDate (value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  }
}
</script>

<style scoped>

</style>
